import { Modal, Backdrop, SpeedDial, SpeedDialAction, Box, Grid } from '@mui/material'
import React, { PureComponent, useEffect, useState } from 'react'
import logo from '../res/logo.jpg'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { db } from '../firebase'
import { ref, onValue } from 'firebase/database';
// import dummyData from '../res/data.json';
import InfoCard from './InfoCard';

import EntryForm from './EntryForm';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined'
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import CreateIcon from '@mui/icons-material/Create';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { off, set, push, child, update } from 'firebase/database';
import ClientTable from './ClientTable';
import CollapseComponent from './CollapseComponent';
import ServiceTable from './ServiceTable';
import moment from 'moment';
import RevenueChart from './RevenueChart';
import ClosableCard from './ClosableCard';
import { Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { availableServices } from '../utils/constants'
import { pink } from '@mui/material/colors';
import SalesCalendar from './SalesCalendar';
// import RevenueCalendar from './RevenueCalendar';
const pinkColor = "#e7348e";
const LOCAL_STORAGE_KEY = 'olivia-sales';
function Dashboard() {
  const [selectedTab, setSelectedTab] = useState("MONTH");
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState([])
  const [revenue, setRevenue] = useState({ "repeat": 0, "new": 0, "total": 0 });
  const [prevMonthRevenue, setPrevMonthRevenue] = useState({ "repeat": 0, "new": 0, "total": 0 });
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);
  const [isNewEntryFormOpen, setIsNewEntryFormOpen] = useState(false)
  const [customerCount, setCustomerCount] = useState({ "repeat": 0, "new": 0, "total": 0 })
  const [prevMonthCustomerCount, setPrevMonthCustomerCount] = useState({ "repeat": 0, "new": 0, "total": 0 })
  const [recentSalesEntries, setRecentSalesEntries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [prevMonthData, setPrevMonthData] = useState();
  const [cmServicesCount, setCmServicesCount] = useState(null);
  const [pmServicesCount, setPmServicesCount] = useState(null);
  const [weeklyRevenueData, setWeeklyRevenueData] = useState(null);
  const [todayRevenue, setTodayRevenue] = useState(0);
  const [todayNoOfCustomers, setTodayNoOfCustomers] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = React.useState('all');
  const [salesCalendarData, setSalesCalendarData] = React.useState({});
  const [customerCalendarData, setCustomerCalendarData] = React.useState({});
  const [currentYear, setCurrentYear] = React.useState(new Date().getFullYear());
  const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const openAddNewEntryForm = () => {
    setIsNewEntryFormOpen(true);
    handleSpeedDialClose();
  }
  // Function to fetch data from local storage
  const fetchDataFromLocalStorage = () => {
    const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    return cachedData ? JSON.parse(cachedData) : null;
  }
  // Function to store data in local storage
  const storeDataInLocalStorage = (data) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  }
  const aggregateRevenueByWeek = (data) => {
    const weeklyRevenue = {};

    data.forEach(({ date, bill }) => {
      const week = moment(date).startOf('isoWeek').format('MM/DD/YYYY');
      if (!weeklyRevenue[week]) {
        weeklyRevenue[week] = 0;
      }
      weeklyRevenue[week] += bill;
    });

    // Transform the aggregated data into an array suitable for Recharts
    return Object.keys(weeklyRevenue).map(week => ({
      week,
      revenue: weeklyRevenue[week],
    }));
  };


  const speedDialActions = [
    { icon: <FileCopyIcon />, name: 'Copy', onClick: null },
    { icon: <SaveIcon />, name: 'Save', onClick: null },
    { icon: <PrintIcon />, name: 'Print', onClick: null },
    { icon: <CreateIcon />, name: 'Add', onClick: openAddNewEntryForm },
  ];
  const handleModalClose = () => {
    setIsNewEntryFormOpen(false);
  }
  const handleSpeedDialClose = () => {
    setIsSpeedDialOpen(false);
  }
  const handleSpeedDialOpen = () => {
    setIsSpeedDialOpen(true);
  }
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  }
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  }
  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
  }
  const getRecentSalesEntries = (numberOfEntries, data) => {
    let allSales = [];
    for (let month in data) {
      for (let day in data[month]) {
        // Check if there are sales entries for this day
        const salesEntries = data[month][day];
        if (salesEntries !== null && typeof salesEntries === 'object') {
          // Iterate over each sales entry
          for (let key in salesEntries) {
            allSales.push({ ...salesEntries[key], timestamp: new Date(salesEntries[key].timestamp), key: key });
          }
        }
      }
    }
    // Sort sales entries by timestamp in descending order
    allSales.sort((a, b) => b.timestamp - a.timestamp);
    return allSales.slice(0, numberOfEntries);
  }

  function parseCurrency(currencyString) {
    // Remove non-numeric characters (except for periods)
    const numericString = currencyString.replace(/[^0-9.]/g, '');

    // Parse the numeric string as a floating-point number
    const numericValue = parseFloat(numericString);

    return numericValue;
  }
  function formatCurrencyToRupee(amount, locale = 'en-IN') {
    // Create a new Intl.NumberFormat object with the specified options
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'INR',
      currencyDisplay: 'symbol'
    });

    // Format the number using the formatter object
    const formattedAmount = formatter.format(amount);

    return formattedAmount;
  }

  function countTotalRevenue(filteredDataRange) {
    let revenueObject = {
      "new": 0,
      "repeat": 0,
      "total": 0
    };
    // let totalSpend = 0;
    filteredDataRange.map((entry) => {
      if (entry) {
        let bill = parseCurrency(entry["bill"].toString());
        if (entry.type.toUpperCase() === "NEW") {
          revenueObject["new"] += bill;
        } else {
          revenueObject["repeat"] += bill;
        }
        revenueObject["total"] += bill
      }
    })
    return revenueObject;
  }
  function countTodayRevenue(filteredDataRange) {
    const todayDateString = formatDate(new Date());
    let revenueObject = {
      "new": 0,
      "repeat": 0,
      "total": 0
    };
    // let totalSpend = 0;
    const todayEntries = filteredDataRange.filter((entry) => {
      return entry.date === todayDateString;
    })
    if (todayEntries) {
      todayEntries.map((entry) => {
        if (entry) {
          let bill = parseCurrency(entry["bill"].toString());
          if (entry.type.toUpperCase() === "NEW") {
            revenueObject["new"] += bill;
          } else {
            revenueObject["repeat"] += bill;
          }
          revenueObject["total"] += bill
        }
      })
    }
    return revenueObject;
  }
  function countTodayNoOfCustomers(filteredDataRange) {
    const todayDateString = formatDate(new Date());
    let customerCount = {
      "new": 0,
      "repeat": 0,
      "total": 0
    };
    const todayEntries = filteredDataRange.filter((entry) => {
      return entry.date === todayDateString;
    })
    if (todayEntries) {
      todayEntries.map((salesObject) => {
        if (salesObject.type.toUpperCase() === "NEW") {
          customerCount["new"] += 1;
        } else {
          customerCount["repeat"] += 1;
        }
        customerCount["total"] += 1;
      });
    }
    return customerCount;
  }
  function getCustomerCount(filteredDataRange) {
    let customerCount = {
      "repeat": 0,
      "new": 0,
      "total": 0
    }
    filteredDataRange.map((salesObject) => {
      if (salesObject.type.toUpperCase() === "NEW") {
        customerCount["new"] += 1;
      } else {
        customerCount["repeat"] += 1;
      }
      customerCount["total"] += 1;
    });
    return customerCount;
  }
  const getMonthData = (data, selectedMonthIndex) => {
    selectedMonthIndex = selectedMonthIndex === 0 ? 12 : selectedMonthIndex;
    const monthDataObject = data[selectedMonthIndex];
    const monthDataList = [];
    for (let dateKey in monthDataObject) {
      for (let entryKey in monthDataObject[dateKey]) {
        monthDataList.push(monthDataObject[dateKey][entryKey])
      }
    }
    return monthDataList;
  }
  const getMonthNameFromIndex = (index) => {
    index = index === 0 ? 12 : index;
    return monthList[index - 1];
  }
  const getServicesCountList = (selectedData, attendantName = "all") => {
    const serviceCountObject = {};
    let serviceCountList = [];
    selectedData.map((salesObject) => {
      let attendantString = salesObject["attendant"].toLowerCase().replace(/ /g, '');
      let servicesList = salesObject["services"];

      for (let i = 0; i < servicesList.length; i++) {
        const serviceLabel = servicesList[i];
        if (attendantName !== "all" && attendantString.toLowerCase() !== attendantName) {
          continue;
        }
        if (Object.keys(serviceCountObject).includes(serviceLabel)) {
          serviceCountObject[serviceLabel] += 1;
        } else {
          serviceCountObject[serviceLabel] = 0;
          serviceCountObject[serviceLabel] += 1;
        }
      }
    });
    for (let i = 0; i < availableServices.length; i++) {
      const label = availableServices[i].label;
      if (Object.keys(serviceCountObject).includes(label)) {
        serviceCountList.push({ "name": label, "count": serviceCountObject[label] });
      } else {
        serviceCountList.push({ "name": label, "count": 0 });
      }
    }
    serviceCountList = serviceCountList.sort((a, b) => b.count - a.count);
    return serviceCountList;
  }
  function formatDate(date) {
    const month = String(date.getMonth() + 1);//.padStart(2, '0');
    const day = String(date.getDate());//.padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  function getPerDaySalesData(filteredDataRange) {
    let perDaySalesData = {};
    filteredDataRange.map((salesObject) => {
      if (perDaySalesData[salesObject.date] === undefined) {
        perDaySalesData[`${salesObject.date}`] = 0;
      }
      perDaySalesData[`${salesObject.date}`] += salesObject.bill;
    })
    return perDaySalesData;
  }
  //
  function getPerDayCustomerCount(filteredDataRange) {
    let perDayCustomerCount = {};
    filteredDataRange.map((salesObject) => {
      if (perDayCustomerCount[salesObject.date] === undefined) {
        perDayCustomerCount[`${salesObject.date}`] = 0;
      }
      perDayCustomerCount[`${salesObject.date}`] += 1;
    })
    return perDayCustomerCount;
  }
  useEffect(() => {
    const fetchData = async () => {
      const year = selectedYear;

      // Reference to the data node for the specified year in your database
      const yearRef = ref(db, `sales/${year}`);
      // Fetch data from local storage
      let localData = fetchDataFromLocalStorage();

      if (localData) {
        // Use data from local storage
        setData(localData);
      } else {
        // Fetch data from database
        onValue(yearRef, (snapshot) => {
          const data = snapshot.val();
          storeDataInLocalStorage(data); // Update local storage with new data
          localData = data;
          setData(data);
        });
      }

      // Set up Firebase listener for data changes
      const unsubscribe = onValue(yearRef, (snapshot) => {
        const updatedData = snapshot.val();
        setData(updatedData);
        // Update local storage with new data
        storeDataInLocalStorage(updatedData);
      });

      // Clean up the Firebase listener when the component unmounts
      return () => {
        off(yearRef);
        unsubscribe();
      };
    };

    fetchData();
    // setMonthlyTimeRange();

    // Cleanup function
    // return () => ref(db,'sales').off();
  }, [selectedYear]);
  useEffect(() => {
    if (data) {
      const currentMonthData = getMonthData(data, selectedMonth);
      setFilteredData(currentMonthData);
      const prevMonthData = getMonthData(data, selectedMonth - 1);
      setPrevMonthData(prevMonthData);
      const salesEntriesData = getRecentSalesEntries(32, data);
      setRecentSalesEntries([...salesEntriesData]);
    }
  }, [data])
  useEffect(() => {
    const revenue = countTotalRevenue(filteredData);
    setRevenue(revenue);

    const customerCount = getCustomerCount(filteredData);
    setCustomerCount(customerCount);

    let servicesCountList = getServicesCountList(filteredData);
    setCmServicesCount(servicesCountList);
    // console.log(servicesCountList);
    const weeklySalesDistribution = aggregateRevenueByWeek(filteredData);
    setWeeklyRevenueData(weeklySalesDistribution);

    const todaysRevenue = countTodayRevenue(filteredData);
    const todaysNoOfCustomers = countTodayNoOfCustomers(filteredData);
    setTodayNoOfCustomers(todaysNoOfCustomers)
    setTodayRevenue(todaysRevenue);

    const perDaySalesData = getPerDaySalesData(filteredData);
    setSalesCalendarData(perDaySalesData);
    const perDayCustomerCount = getPerDayCustomerCount(filteredData)
    setCustomerCalendarData(perDayCustomerCount);
  }, [filteredData])
  useEffect(() => {
    if (prevMonthData) {
      const revenue = countTotalRevenue(prevMonthData);
      setPrevMonthRevenue(revenue);

      const customerCount = getCustomerCount(prevMonthData);
      setPrevMonthCustomerCount(customerCount);

      let servicesCountList = getServicesCountList(prevMonthData);
      setPmServicesCount(servicesCountList);
    }
  }, [prevMonthData])
  useEffect(() => {
    if (data) {
      const selectedMonthDataList = getMonthData(data, selectedMonth);;
      setFilteredData(selectedMonthDataList);
      // set prev month data
      const prevMonthIndex = selectedMonth - 1;
      const prevMonthDataList = getMonthData(data, prevMonthIndex);
      setPrevMonthData(prevMonthDataList);
    }
  }, [selectedMonth, selectedYear])
  useEffect(() => {
    if (filteredData && Array.isArray(filteredData) && filteredData.length > 0) {
      let cmServicesCountList = getServicesCountList(filteredData, selectedEmployee);
      setCmServicesCount(cmServicesCountList);
    }
    if (prevMonthData && Array.isArray(prevMonthData) && prevMonthData.length > 0) {
      let pmServicesCountList = getServicesCountList(prevMonthData, selectedEmployee);
      setPmServicesCount(pmServicesCountList);
    }
  }, [selectedEmployee, filteredData, prevMonthData])
  return (
    <>

      <Grid container spacing={2}>
        {/* <Grid item xs={12} sx={{backgroundColor:"black"}}>
        <RevenueCalendar></RevenueCalendar>
        </Grid> */}

        <Grid item xs={12}>
          <FormControl size="small">
            <InputLabel id="month-select-label" sx={{ color: pinkColor }}>Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              label="Year"
              onChange={handleYearChange}
              disabled={false}
              readOnly={false}
              sx={{
                color: "white",
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '.MuiSvgIcon-root ': {
                  fill: "white !important",
                }
              }}
            >
              <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
              <MenuItem value={currentYear}>{currentYear}</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small">
            <InputLabel id="month-select-label" sx={{ color: pinkColor }}>Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={selectedMonth}
              label="Month"
              onChange={handleMonthChange}
              sx={{
                color: "white",
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '.MuiSvgIcon-root ': {
                  fill: "white !important",
                }
              }}
            >
              {
                monthList.map((name, index) => {
                  return <MenuItem value={index + 1}>{name}</MenuItem>
                })
              }

            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={12}>
          <Typography variant="h6" style={{ color: "white", textAlign: "left" }}>
            {`Today's Revenue: ${formatCurrencyToRupee(todayRevenue.total)}`}
          </Typography>
          <Typography variant="h6" style={{ color: "white", textAlign: "left" }}>
            {`Number of Customers: ${todayNoOfCustomers.total}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CollapseComponent title={"Recent sales entries"} defaultOpen={true}>
            <ClientTable salesData={recentSalesEntries}></ClientTable>
          </CollapseComponent>
          <Divider color="white"></Divider>
        </Grid>
        <Grid container item xs={12}>
          {/* <CollapseComponent title={"Total Stats"}> */}
          <Grid item xs={4}>
            <InfoCard title="Total Revenue" selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)} number={formatCurrencyToRupee(revenue["total"])} comparisonNumber={formatCurrencyToRupee(prevMonthRevenue["total"])}></InfoCard>
          </Grid>
          <Grid item xs={4}>
            <InfoCard title="Total Customers" number={customerCount["total"]} comparisonNumber={prevMonthCustomerCount["total"]} selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)}></InfoCard>
          </Grid>
          <Grid item xs={4}>
            <InfoCard title="Average Spend" number={formatCurrencyToRupee((revenue["total"] / customerCount["total"]))} comparisonNumber={formatCurrencyToRupee(prevMonthRevenue["total"] / prevMonthCustomerCount["total"])} selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)}></InfoCard>
          </Grid>
          {/* </CollapseComponent> */}
        </Grid>
        <Grid item xs={12}>
          <Divider color="white"></Divider>
        </Grid>
        <Grid item xs={4}>
          <InfoCard title="Repeat Revenue" number={formatCurrencyToRupee(revenue["repeat"])} comparisonNumber={formatCurrencyToRupee(prevMonthRevenue["repeat"])} selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)}></InfoCard>
        </Grid>
        <Grid item xs={4}>
          <InfoCard title="Repeat Customers" number={customerCount["repeat"]} comparisonNumber={prevMonthCustomerCount["repeat"]} selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)}></InfoCard>
        </Grid>
        <Grid item xs={4}>
          <InfoCard title="Average Spend" number={formatCurrencyToRupee((revenue["repeat"] / customerCount["repeat"]))} comparisonNumber={formatCurrencyToRupee(prevMonthRevenue["repeat"] / prevMonthCustomerCount["repeat"])} selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)}></InfoCard>
        </Grid>
        <Grid item xs={12}>
          <Divider color="white"></Divider>
        </Grid>
        <Grid item xs={4}>
          <InfoCard title="New Revenue" number={formatCurrencyToRupee(revenue["new"])} comparisonNumber={formatCurrencyToRupee(prevMonthRevenue["new"])} selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)} ></InfoCard>
        </Grid>
        <Grid item xs={4}>
          <InfoCard title="New Customers" number={customerCount["new"]} comparisonNumber={prevMonthCustomerCount["new"]} selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)}></InfoCard>
        </Grid>
        <Grid item xs={4}>
          <InfoCard title="Average Spend" number={formatCurrencyToRupee((revenue["new"] / customerCount["new"]))} comparisonNumber={formatCurrencyToRupee(prevMonthRevenue["new"] / prevMonthCustomerCount["new"])} selectedMonthName={getMonthNameFromIndex(selectedMonth)} prevMonthName={getMonthNameFromIndex(selectedMonth - 1)}></InfoCard>
        </Grid>
        <Grid item xs={12}>
          <CollapseComponent title={"Services Count"} defaultOpen={true}>
            <FormControl>
              {/* <FormLabel sx={{color:"white"}}id="demo-row-radio-buttons-group-label">Staff</FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedEmployee}
                onChange={handleEmployeeChange}
              >
                <FormControlLabel value="all" sx={{ color: "white" }} control={<Radio size="small" sx={{
                  color: pink[800],
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }} />} label="All" />
                <FormControlLabel value="kajal" sx={{ color: "white" }} control={<Radio size="small" sx={{
                  color: pink[800],
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }} />} label="Kajal" />
                <FormControlLabel value="shivani" sx={{ color: "white" }} control={<Radio size="small" sx={{
                  color: pink[800],
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }} />} label="Shivani" />
                <FormControlLabel value="s2" sx={{ color: "white" }} control={<Radio size="small" sx={{
                  color: pink[800],
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }} />} label="Shivani 2" />
                <FormControlLabel value="vaishnavi" sx={{ color: "white" }} control={<Radio size="small" sx={{
                  color: pink[800],
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }} />} label="Vaishnavi" />
              </RadioGroup>
            </FormControl>
            <ServiceTable cmServiceCountData={cmServicesCount}
              pmServiceCountData={pmServicesCount}></ServiceTable>
          </CollapseComponent>
        </Grid>
        <Grid item xs={12}>
          <CollapseComponent title={"Sales Calendar"} defaultOpen={false}>
            <SalesCalendar salesData={salesCalendarData} customerCountData={customerCalendarData} month={selectedMonth} year={selectedYear} />
          </CollapseComponent>
        </Grid>
        <Grid item xs={12}>
          <CollapseComponent title={"Weekly Revenue Distribution"} defaultOpen={true}>
            <RevenueChart data={weeklyRevenueData} height={400}></RevenueChart>
          </CollapseComponent>
        </Grid>
        <Grid item xs={12}>
          <Modal
            open={isNewEntryFormOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflow: "auto" }}
          >
            <Grid container justifyContent="center" // Horizontally center the items
              alignItems="center" // Vertically center the items
              style={{ minHeight: '100vh' }} >
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: 'rgba(255,255,255,1)', minHeight: '100%' }}>
                  <EntryForm onModalClose={handleModalClose} />
                </Box>

              </Grid>
            </Grid>
          </Modal>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ height: 330, flexGrow: 1 }}>
            <Backdrop open={isSpeedDialOpen} sx={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} />
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              sx={{ position: 'fixed', bottom: 15, right: 15 }}
              icon={<SpeedDialIcon />}
              onClose={handleSpeedDialClose}
              onOpen={handleSpeedDialOpen}
              open={isSpeedDialOpen}

              FabProps={{
                sx: {
                  '&:hover': {
                    backgroundColor: pinkColor, // Set your desired color here
                  },
                  backgroundColor: pinkColor,
                } // Set the button color here
              }}
            >
              {speedDialActions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={action.onClick}
                />
              ))}
            </SpeedDial>
          </Box>

        </Grid>
        {/* <Grid item>
                  <Button onClick={pushDataToDB}>Push Data to DB</Button>
                </Grid> */}
        {/* <ResponsiveContainer width="50%" height="50%">
      <LineChart
        width={500}
        height={300}
        data={dummyData}
        margin={{
          top: 0,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer> */}

      </Grid>

    </>
  )
}

export default Dashboard