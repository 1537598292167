export const availableServices = [
    { label: "Threading", value: "Threading" },
    { label: "Brazilian Wax", value: "Brazilian Wax" },
    { label: "Waxing - Honey", value: "Waxing - Honey" },
    { label: "Waxing - Rica", value: "Waxing - Rica" },
    { label: "Bikini Wax", value: "Bikini Wax" },
    { label: "Bleach", value: "Bleach" },
    { label: "D-Tan", value: "D-Tan" },
    { label: "Clean up", value: "Clean up" },
    { label: "Facial", value: "Facial" },
    { label: "Facial - O3+", value: "Facial - O3+" },
    { label: "Hair cut", value: "Hair cut" },
    { label: "Hair Wash", value: "Hair Wash" },
    { label: "Ironing", value: "Ironing" },
    { label: "Hair Spa", value: "Hair Spa" },
    { label: "Hair - Root Touchup", value: "Hair - Root Touchup" },
    { label: "Hair - Global", value: "Hair - Global" },
    { label: "Hair - Blow Dry", value: "Hair - Blow Dry" },
    { label: "Manicure", value: "Manicure" },
    { label: "Pedicure", value: "Pedicure" },
    { label: "Makeup", value: "Makeup" },
    { label: "Hair - Bluetox Treatment", value: "Hair - Bluetox Treatment" },
    { label: "Head Massage", value: "Head Massage" },
    { label: "Dandruff Treatment", value: "Dandruff Treatment" },
    { label: "Body Massage", value: "Body Massage" },
    { label: "Body Polishing", value: "Body Polishing" },
    { label: "Mehendi", value: "Mehendi" },
    { label: "Underarms", value: "Underarms" },
    { label: "Hand massage", value: "Hand massage" },
    { label: "Foot massage", value: "Foot massage" },
    { label: "Upper lips", value: "Upper lips" },
    { label: "Saree Draping", value: "Saree Draping" },
    { label: "Nail Art", value: "Nail Art" },
    { label: "Hair Styling", value: "Hair Styling" },
    { label: "Other", value: "Other" }
];
export const societyOptions = [
    { label: "Kaizen", value: "Kaizen" },
    { label: "F - Residences", value: "F - Residences" },
    { label: "Konark", value: "Konark" },
    { label: "Kunal Aspiree", value: "Kunal Aspiree" },
    { label: "Narayani", value: "Narayani" },
    { label: "K - Square", value: "K - Square" },
    { label: "Aryan Empire", value: "Aryan Empire" },
    { label: "Chloris", value: "Chloris" },
    { label: "Aditya Breeze Park", value: "Aditya Breeze Park" },
    { label: "Sai Silicon Valley", value: "Sai Silicon Valley" },
    { label: "Lemon Blossom", value: "Lemon Blossom" },
    { label: "Sanskruti Homes", value: "Sanskruti Homes" },
    { label: "Miracle Glory", value: "Miracle Glory" },
    { label: "Yugal Drashila", value: "Yugal Drashila" },
    { label: "Youthville Hostel", value: "Youthville Hostel" },
    { label: "Anjaneya Apartment", value: "Anjaneya Apartment" },
    { label: "Nishigandh Apartment", value: "Nishigandh Apartment" },
    { label: "Devashish Apartment", value: "Devashish Apartment" },
    { label: "Destiny Hotel", value: "Destiny Hotel" },
    { label: "Sai Ankoor", value: "Sai Ankoor" },
    { label: "43 Drive", value: "43 Drive" },
    { label: "Elite Empire", value: "Elite Empire" },
    { label: "Bliss Avenue", value: "Bliss Avenue" },
    { label: "Royal Park", value: "Royal Park" },
    { label: "Madhuban", value: "Madhuban" },
    { label: "Sapphire Park", value: "Sapphire Park" },
    { label: "Kundan Espacio", value: "Kundan Espacio" },
    { label: "Aura Apartment", value: "Aura Apartment" },
    { label: "Chaitanya Platinum", value: "Chaitanya Platinum" },
    { label: "Park Xpress", value: "Park Xpress" },
    { label: "Golden Castle", value: "Golden Castle" },
    { label: "Sai Canary", value: "Sai Canary" },
    { label: "Astonia", value: "Astonia" },
    { label: "Wonderwall", value: "Wonderwall" },
    { label: "Concord Portia", value: "Concord Portia" },
    { label: "Metropark County", value: "Metropark County" },
    { label: "Imperium Sky Garden", value: "Imperium Sky Garden" },
    { label: "Majestique Signature Towers", value: "Majestique Signature Towers" },
    { label: "Park Grandeur", value: "Park Grandeur" },
    { label: "Royal Vatika", value: "Royal Vatika" },
    { label: "Vanshaj Kiona", value: "Vanshaj Kiona" },
    { label: "ANP UNIVERSE", value: "ANP UNIVERSE" },
    { label: "Palladio", value: "Palladio" },
    { label: "51 BALEWADI", value: "51 BALEWADI" },
    { label: "Rigved Uptown", value: "Rigved Uptown" },
    { label: "Rohan Ekam", value: "Rohan Ekam" },
    { label: "The Balmoral Towers", value: "The Balmoral Towers" },
    { label: "Kunal Canary", value: "Kunal Canary" },
    { label: "The Canary Residence Collection", value: "The Canary Residence Collection" },
    { label: "Palazzo", value: "Palazzo" },
    { label: "Platinum Atlantis", value: "Platinum Atlantis" },
    { label: "7 Avenues", value: "7 Avenues" },
    { label: "Wisdom Park", value: "Wisdom Park" },
    { label: "Vatika Homes", value: "Vatika Homes" },
    { label: "Mayflower Residency", value: "Mayflower Residency" },
    { label: "Nirmiti Zion", value: "Nirmiti Zion" },
    { label: "Prakashgad Society", value: "Prakashgad Society" },
    { label: "The Terraces", value: "The Terraces" },
    { label: "Gandhar Apartments", value: "Gandhar Apartments" },
    { label: "Neev Apartment", value: "Neev Apartment" },
    { label: "Mont Vert Corsica", value: "Mont Vert Corsica" },
    { label: "Riddhi Siddhi Society", value: "Riddhi Siddhi Society" },
    { label: "TouchStone Apartment", value: "TouchStone Apartment" },
    { label: "The Pearl", value: "The Pearl" },
    { label: "Regulus", value: "Regulus" },
    { label: "Karan Celista", value: "Karan Celista" },
    { label: "Faith Society", value: "Faith Society" },
    { label: "The Parkland", value: "The Parkland" },
    { label: "PG", value: "PG" },
    { label: "Walk-in", value: "Walk-in" },
    { label: "Other", value: "Other" }
];